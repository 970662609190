import 'blueimp-gallery/js/blueimp-gallery.min.js';

/**
 * TODO: Replace blueimp-gallery solution (Swiper: https://swiperjs.com/)
 */
document.addEventListener('click', event => {
    const target = event.target as HTMLElement | null;
    if (!target) {
        return;
    }

    const trackedEl = target.closest('[data-gallery]:not([data-toggle])') as HTMLElement | null;
    if (!trackedEl) {
        return;
    }

    const id = trackedEl.dataset.gallery!;
    const widget = document.getElementById(id);
    const container = widget || document.querySelector(window.blueimp.Gallery.prototype.options.container);

    if (!container) {
        return;
    }

    event.preventDefault();

    const options = Object.assign(
        {},
        // Retrieve custom options from data-attributes on the Gallery widget:
        container.dataset,
        {
            container:    container,
            index:        trackedEl,
            event:        event,
            titleElement: 'p',
        },
    );

    // Select all links with the same data-gallery attribute:
    const links = trackedEl.closest('.product-images')?.querySelectorAll('a[data-gallery]') || [trackedEl];

    window.blueimp.Gallery(links, options);
});

const $blueimpGallery = $('#blueimp-gallery');
$blueimpGallery.data('useBootstrapModal', false)
    .toggleClass('blueimp-gallery-controls', true)
/* NDT-8635: fix for after swipe */
    .on(
        'slideend',
        function(event, index) {
            const indicators           = $(this).find(window.blueimp.Gallery.prototype.options.indicatorContainer).children();
            const activeIndicatorClass = window.blueimp.Gallery.prototype.options.activeIndicatorClass;
            let activeIndicator;
            indicators &&
            indicators.removeClass(activeIndicatorClass) &&
            (activeIndicator = $(indicators[index]), activeIndicator.addClass(activeIndicatorClass));
        },
    );

// NDT-7210: Fixing for "Windows 10 + Edge"
$blueimpGallery.on('closed', function() {
    if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = '';
    }
});

/* Irina: Controlls der Lightbox beim Abspielen von YouTube-Videos nicht ausblenden */
window.blueimp.Gallery.prototype.YouTubePlayer.prototype.onPlaying = function() {
    if (this.playStatus < 2) {
        this.listeners.playing();
        this.playStatus = 2;
    }

    const container     = window.blueimp.Gallery.prototype.options.container,
          controlsClass = window.blueimp.Gallery.prototype.options.controlsClass;
    $(container).addClass(controlsClass);
};
